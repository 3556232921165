
import { defineComponent } from 'vue';
import { createPasswordReset, tryResetPassword } from '@/services/steamcord';
import { validateRequired } from '@/services/validation';
import { validatePasswords } from '@/services/validation/auth';
import Modal from '@/components/Modal.vue';

export default defineComponent({
  components: {
    Modal,
  },
  data() {
    return {
      formError: '',
      loading: false,
      password: '',
      passwordAgain: '',
      passwordError: '',
      resendEmailLoading: false,
      showModal: false,
      verificationCode: '',
      verificationCodeError: '',
    };
  },
  methods: {
    async onSubmit() {
      if (this.loading) {
        return;
      }

      const verificationError = validateRequired(this.verificationCode);
      const passwordError = validatePasswords(this.password, this.passwordAgain);
      if (passwordError || verificationError) {
        this.passwordError = passwordError;
        this.verificationCodeError = verificationError;
        return;
      }

      this.loading = true;

      const email = this.$route.params.email.toString();

      if (await tryResetPassword(email, this.verificationCode, this.password)) {
        this.$router.push({ name: 'Sign In Form' });
        return;
      }

      this.passwordError = passwordError;
      this.verificationCodeError = 'Invalid verification code.';
      this.loading = false;
    },
    async resendEmail() {
      if (this.resendEmailLoading) {
        return;
      }

      this.resendEmailLoading = true;

      const email = this.$route.params.email.toString();

      await createPasswordReset(email);

      this.resendEmailLoading = false;

      this.showModal = true;
    },
  },
});
